import Api from "../../Api";

const END_POINT_ALLPRODUCTOTERMINADO = 'user/finished-product/all';
const END_POINT_CREATEPRODUCTOTERMINADO = 'user/finished-product/create';
const END_POINT_UPDATEPRODUCTOTERMINADO = 'user/finished-product/update';
const END_POINT_DELETEPRODUCTOTERMINADO = 'user/finished-product/delete';

export default {
  GetAllProductoTerminado(data){
    return Api.get(END_POINT_ALLPRODUCTOTERMINADO, {
      headers: {
        'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  PostCreateProductoTerminado(data, auth) {
    return Api.post(END_POINT_CREATEPRODUCTOTERMINADO, {
      'codigo': data.codigo,
      'descripcion': data.descripcion,
      'kgs_procesados': data.kgs_procesados,
      'maximo': data.maximo,
      'minimo': data.minimo,
      'finished_product_family_id': data.finished_product_family_id,
      'finished_product_unit_id': data.finished_product_unit_id,
      'calibre':data.calibre
    }, {
        headers: {
          'Authorization': 'Bearer ' + auth.access_token
        }
    });
  },

  UpdateProductoTerminado(data, auth) {
    return Api.patch(END_POINT_UPDATEPRODUCTOTERMINADO, {
      'id': data.id,
      'codigo': data.codigo,
      'descripcion': data.descripcion,
      'kgs_procesados': data.kgs_procesados,
      'maximo': data.maximo,
      'minimo': data.minimo,
      'finished_product_family_id': data.finished_product_family_id,
      'finished_product_unit_id': data.finished_product_unit_id,
      'calibre':data.calibre
    }, {
        headers: {
          'Authorization': 'Bearer ' + auth.access_token
        }
    });
  },

  DeleteByProductoTerminado(id, auth) {
    return Api.delete(END_POINT_DELETEPRODUCTOTERMINADO + '/' + id, {
        headers: {
          'Authorization': 'Bearer ' + auth.access_token
        },
    });
  }

}